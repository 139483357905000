.home {
  font-family: "Lora", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
}

.centerTable {
  margin: auto;
}
