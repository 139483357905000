/*------ page styling -----*/
.mainselection select {
  border: 0;
  color: #eee;
  /* background-image: url("../img/tutor/gold.png"); */
  background: transparent;
  font-size: 20px;
  font-weight: bold;
  padding: 2px 10px;
  width: 378px;
  -webkit-appearance: none;
}

.mainselection {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 350px;
  -moz-border-radius: 9px 9px 9px 9px;
  -webkit-border-radius: 9px 9px 9px 9px;
  border-radius: 9px 9px 9px 9px;
  box-shadow: 1px 1px 11px #330033;
  /* background-image: url("../img/tutor/gold.png") no-repeat scroll 319px center; */
  background: gold no-repeat scroll 319px center;
}

.textbox {
  height: 25px;
  width: 275px;
  border: solid 1px #e5e5e5;
  outline: 0;
  font: normal 13px/100% Verdana, Tahoma, sans-serif;
  /* background: #fff url("bg_form.png") left top repeat-x;  */
  background: -webkit-gradient(
    linear,
    left top,
    left 25,
    from(#fff),
    color-stop(4%, #eee),
    to(#fff)
  );
  background: -moz-linear-gradient(top, #ffffff, #eeeeee 1px, #ffffff 25px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
  -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
}
.textbox :focus {
  border-color: #c9c9c9;
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 8px;
}
#start-tutorial {
}

.ImageBorder {
  border-width: 5px;
  border-color: whitesmoke;
}

.myimg {
  margin-top: 10px;
  border: 2px solid;
  border-color: aliceblue;
}

h2-image {
  position: inherit;
  top: 70%;
  /*   left:5%; */
  width: auto;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: grey;
}
h2-image span {
  color: white;
  font: bold 20px/45px Helvetica, Sans-Serif;
  letter-spacing: 0px;
  background: rgb(0, 0, 0);
  background: rgba(65, 187, 235, 0.7);
  /*    background-color:cadetblue;*/
  padding: 2px;
}
h2-image span.spacer {
  padding: 0 5px;
}

h4-image {
  position: inherit;
  top: 70%;
  /*   left:5%; */
  width: auto;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: grey;
}

h4-image span {
  color: white;
  font: bold 20px/25px Helvetica, Sans-Serif;
  letter-spacing: 0px;
  background: rgb(0, 0, 0);
  background: rgba(65, 187, 235, 0.7);
  /*    background-color:cadetblue;*/
  padding: 2px;
}
h4-image span.spacer {
  padding: 0 5px;
}

/* logo hover effect*/
.grow {
  transition: all 0.2s ease-in-out;
  position: relative;
  top: 0px;
  /*    cursor: pointer;*/
}
.grow:hover {
  transform: scale(3.1);
  /*
      -webkit-transform: scale(3.1);
      -moz-transform: scale(3.1);
      -o-transform: scale(3.1);
  */
  position: relative;
  top: 50px;
  right: -20px;
}

/*
  .gita-sara{
      position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none;
      
  }*/
/*
  .audio{
      
      display:inline;
  }*/
.audio {
  width: auto;
  right: inherit;
  left: inherit;
  height: auto;
  float: auto;
  overflow: hidden;
  /*    border: 2px;*/
  /*    padding: 2px, 2px,2px,2px;*/
  padding-left: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-right: 5px;
  display: inline-flex;
  vertical-align: middle;
  border-top-right-radius: 0.5em 0.5em;
  border-bottom-right-radius: 1em 0.7em;

  background-color: transparent;
}
.custom-tooltip {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 1px solid cornflowerblue;
  overflow: visible;
  pointer-events: none;
  transition: opacity 1s;
}
.bullets > ul {
  margin-left: 0;
  padding-left: 5;
}
.bullets > li {
  margin-left: 1em;
}
#swamiji {
}

.header-red {
  color: red;
}

.App {
  font-family: sans-serif;
  text-align: center;
  margin: 0 auto;
}

button {
  margin: 5px;
}
